<template>
  <div>
    <div class="mobile-wrapper">
      <div class="home-left center">
        <div class="welcome-block">
          <div class="feed-title bold">Welcome Back!</div>
          <div class="welcome-note bold">
            Live The fabulous Life. Fabulous Fashion. Fabulous Parties. Fabulous Friends.
            <div class="center">
              <router-link exact tag="div" class="cursor" to="/game">
                <img src="//assets.sororitywars.app/icons/play_now_new.png" class="img-options" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoggedIn'
}
</script>

<style scoped>
  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .home-left {
    margin-right: 15px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }

  @media only screen and (min-width: 769px) {
    /* Desktop */
    .img-options {
      cursor: pointer;
      width: 1000px;
    }

    .welcome-block {
      text-align: left;
      background: #d1ebf1 url("//assets.sororitywars.app/backgrounds/blue-background.jpg") repeat center top;
      padding: 10px 5px;
      border: 1px solid #238cbd;
      border-radius: 6px;
      -moz-border-radius: 6px;
      width: 98%;
      display: inline-block;
    }

    .welcome-note {
      color: #000000;
      font-size: 18px;
    }

    .click-to-play {
      font-size: 22px;
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile */
    .img-options {
      margin: 2px auto;
      cursor: pointer;
      width: 98%;
    }

    .welcome-block {
      text-align: left;
      background: #d1ebf1 url("//assets.sororitywars.app/backgrounds/blue-background.jpg") repeat center top;
      padding: 5px;
      border: 1px solid #238cbd;
      border-radius: 6px;
      -moz-border-radius: 6px;
      width: 94%;
      display: inline-block;
    }

    .welcome-note {
      color: #000000;
      font-size: 16px;
    }

    .click-to-play {
      font-size: 18px;
    }
  }

  .feed-title {
    font-size: 22px;
    border-bottom: 1px solid #000000;
    padding-bottom: 2px;
    margin: 0 0 4px 0;
  }

  .house-mom-signature {
    font-size: 18px;
  }

</style>
