const reduceOutErrorsFromApi = errors => {
  if (Array.isArray(errors)) {
    return errors.reduce((a, item) => {
      let temp = reduceOutErrorsFromApi(item)
      if (Array.isArray(temp)) a.push(...temp)
      else a.push(temp)
      return a
    }, [])
  } else if (typeof errors === 'object') {
    return Object.keys(errors).reduce((a, key) => {
      let temp = reduceOutErrorsFromApi(errors[key])
      if (Array.isArray(temp)) a.push(...temp)
      else a.push(temp)
      return a
    }, [])
  } else {
    return errors
  }
}

export default {
  install (Vue) {
    Vue.prototype.$errorReducer = function (errors) {
      return reduceOutErrorsFromApi(errors)
    }
  }
}
