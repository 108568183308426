import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'
import Axpi from './api/axpi'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import VueModalTor from 'vue-modaltor'
import numbers from './api/numbers'
import errorReducer from './api/errorReducer'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import './assets/tailwind.css'

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_CAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
})
Vue.use(VueModalTor)
Vue.use(numbers)
Vue.use(errorReducer)

export const bugsnagClient = bugsnag('1c9c1bb1f6a0731d2769aadc060ccc96')
bugsnagClient.use(bugsnagVue, Vue)

Vue.config.productionTip = false

Vue.component('string-viewer-x', {
  props: ['message'],
  name: 'StringViewerX',
  render: function (createElement) {
    return createElement('div', this.message)
  }
})

Axpi.$http().interceptors.response.use(res => {
  const id = res.data.user ? res.data.user.hid : 0
  const name = res.data.player ? res.data.player.name : 'Guest'
  const email = res.data.user ? res.data.user.email : 'marcuz+guest@sororitywars.app'

  if (id && name) {
    window.tid.updateTideo(id, name, email)
  }

  if (res.data.player) store.dispatch('updatePlayer', res.data.player).then()
  if (res.data.user) store.dispatch('updateUser', res.data.user).then()
  return res
}, error => {
  if (error.response && error.response.status === 401) {
    store.dispatch('modCreate').then()
    store.dispatch('logout').then()
    router.push({ name: 'login' }).then()
  } else {
    return Promise.reject(error)
  }
})

Axpi.$http().interceptors.request.use(config => {
  if (store.state.user.captchad && config.method === 'post') {
    if (config.data) {
      config.data.c3p0 = store.state.user.captchaResponse
    } else {
      config.data = { c3p0: store.state.user.captchaResponse }
    }
  }
  if (config.data && config.data.gee) {
    config.data = { ...config.data, ...store.getters['captcha/getGeeShit'] }
  }
  return config
})

store.commit('pageLoad')

router.beforeEach((to, from, next) => {
  if (to.matched.some(m => m.meta.auth)) {
    if (!store.state.user.loggedIn) {
      return next({ name: 'login', query: { _: to.fullPath } })
    }
  }
  if (to.matched.some(m => m.meta.verified)) {
    if (!store.state.user.verified) {
      return next({ name: 'VerificationEmail' })
    }
  }
  return next()
})

Vue.use(Axpi)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
