import SororityWarsPlayerAPI from '@/api/SororityWarsPlayerAPI'

const state = {
  create: false,
  achievements: [],
  name: 'Username Placeholder',
  level: 1,
  energy: 100,
  maxEnergy: 100,
  stamina: 3,
  maxStamina: 3,
  health: 100,
  maxHealth: 100,
  income: 100,
  upkeep: 0,
  cash: 267682,
  bank: null,
  healthTimer: 0,
  energyTimer: 0,
  incomeTimer: 0,
  staminaTimer: 0,
  jobId: 0,
  attack: 0,
  defense: 0,
  skillPoints: 0,
  exp: 0,
  expNextLevel: 0,
  vipUntil: null
}

const mutations = {
  create (state, action) {
    state.create = action
  },
  playerUpdate (state, { name, level, energy, maxEnergy, energyTimer, health, maxHealth, healthTimer, stamina, maxStamina, staminaTimer, income, upkeep, cash, bank, incomeTimer, jobId, attack, defense, skillPoints, exp, expNextLevel, achievements, vipUntil }) {
    state.name = name
    state.level = level
    state.energy = energy
    state.maxEnergy = maxEnergy
    state.energyTimer = energyTimer
    state.health = health
    state.maxHealth = maxHealth
    state.healthTimer = healthTimer
    state.stamina = stamina
    state.maxStamina = maxStamina
    state.staminaTimer = staminaTimer
    state.income = income
    state.upkeep = upkeep
    state.cash = cash
    state.bank = bank
    state.incomeTimer = incomeTimer
    state.jobId = jobId
    state.attack = attack
    state.defense = defense
    state.skillPoints = skillPoints
    state.exp = exp
    state.expNextLevel = expNextLevel
    state.achievements = [ ...state.achievements, ...achievements ]
    state.vipUntil = vipUntil
  },
  timerReducer (state, timer) {
    state[timer + 'Timer'] -= 1
  },
  resetAchievements (state, action) {
    state.achievements = action
  },
  changeHealth (state, val) {
    let health = state.health + val
    state.health = health > 0 ? health : 0
  }
}

const actions = {
  getStats (context) {
    return SororityWarsPlayerAPI.stats()
      .then(r => {
        if (!r.data.player) context.commit('create', true)
      })
  },
  getBankInfo () {
    return SororityWarsPlayerAPI.stats()
      .then(r => {
        return r.data.player
      })
  },
  modCreate (context, val = false) {
    context.commit('create', val)
  },
  createPlayer (context, { name, playerClass }) {
    return SororityWarsPlayerAPI.newGame(name, playerClass).then(r => {
      return r.data
    })
  },
  updatePlayer (context, playerObj) {
    if (playerObj) context.commit('playerUpdate', playerObj)
  },
  timerReducer (context, timer) {
    const upper = timer.replace(/^\w/, c => c.toUpperCase())
    if (context.state[timer + 'Timer'] >= 1) {
      context.commit('timerReducer', timer)
      return true
    } else if (context.state[timer + 'Timer'] === 0) {
      if (timer !== 'income') {
        if (context.state[timer] < context.state[`max${upper}`]) {
          context.dispatch('getStats')
        }
      } else {
        context.dispatch('getStats')
      }
      return false
    } else {
      return false
    }
  },
  increaseAttribute (context, { attribute, amountOfSkillPoints = null }) {
    return SororityWarsPlayerAPI.increaseAttribute(attribute, amountOfSkillPoints)
      .then(r => {
        return r.data
      })
  }
}

const getters = {
  playerName: (state) => {
    return state.name
  }
}

export default { state, actions, mutations, getters }
