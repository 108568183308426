import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/jobs'

const jobsAPI = {
  all () {
    return Axpi.$http().get(`${urlPrefix}/all`)
  },
  start (job) {
    return Axpi.$http().post(`${urlPrefix}/${job}/start`)
  },
  quit (job) {
    return Axpi.$http().post(`${urlPrefix}/${job}/quit`)
  }
}

export default jobsAPI
