<template>
  <transition name="slide-fade">
    <div v-if="showNotifications">
      <div v-if="!hidden" class="notify-box">
        <div class="notify-inner">
          <template v-if="event.type === 'App\\Notifications\\SororityWarsSlap'">
            <Slapped @closeMe="closeMe" :notif="event" />
          </template>
          <template v-if="event.type === 'App\\Notifications\\AttackAction'">
            <Attacking @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\SororityWarsHouseAction'">
            <Invites @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\HouseActionNoFeed'">
            <Invites @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\WhiteboardComment'">
            <Whiteboard @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\HouseMassAddSuccess'">
            <MassAddSuccess @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\UserListedPlayer'">
            <BurnList @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\UserExecutedHit'">
            <BurnListExecute @closeMe="closeMe" :notif="event" />
          </template>
          <template v-else-if="event.type === 'App\\Notifications\\UserDied'">
            <Died @closeMe="closeMe" :notif="event" />
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Slapped from '@/components/notifs/Slapped'
import Died from '@/components/notifs/Died'
import BurnList from '@/components/notifs/BurnList'
import BurnListExecute from '@/components/notifs/BurnListExecute'
import Attacking from '@/components/notifs/Attacking'
import Invites from '@/components/notifs/Invites'
import Whiteboard from '@/components/notifs/Whiteboard'
import MassAddSuccess from '@/components/notifs/MassAddSuccess'
export default {
  name: 'NotificationBase',
  components: { MassAddSuccess, Slapped, Invites, Whiteboard, Attacking, BurnList, Died, BurnListExecute },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    showNotifications () {
      return this.$store.state.user.iNotifications
    }
  },
  data () {
    return {
      hidden: false
    }
  },
  created () {
    setTimeout(() => {
      this.hidden = true
    }, 5000)
  },
  methods: {
    closeMe () {
      this.hidden = true
    }
  }
}
</script>

<style>
  .slide-fade-enter-active {
    transition: all .4s ease;
  }
  .slide-fade-leave-active {
    transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
  .notify-placement {
    bottom: 10px;
    right: 110px;
    position: fixed;
    z-index: 9999;
  }
  @media only screen and (min-width: 769px) {
    .notify-box {
      background-color: #FFFFFF;
      border: 2px solid #e84063;
      border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px;
      margin-top: 5px;
      width: 250px;
      min-height: 60px;
    }

    .notify-left {
      text-align: center;
      width: 50px;
      display: inline-block;
      vertical-align: top;
    }

    .notify-right {
      width: 200px;
      display: inline-block;
      vertical-align: top;
    }

    .notify-others {
      font-size: 12px;
      color: #666666;
      font-style: italic;
    }

    .notify-inner {
      position: relative;
    }

    .notify-close {
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: -5px;
      right: 0;
      cursor: pointer;
    }

    .notify-alert {
      font-size: 20px;
      color: #e84063;
      font-weight: bold;
      text-align: left;
    }

    .notify-title {
      color: #000000;
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }

    .notify-image {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .notify-box {
      width: 45px;
      height: 45px;
      position: fixed;
      right: 10px;
      bottom: 0;
    }

    .notify-inner {
      position: relative;
    }

    .notify-close {
      display: none;
    }

    .notify-alert {
      display: none;
    }

    .notify-title {
      display: none;
    }

    .desktop-notify-image {
      display: none;
    }
  }
</style>
