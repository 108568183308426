<template>
  <div>
    <div class="page-title-box">
      <div class="page-title bold">Weekly In Crowd</div>
      <div class="page-info">The weekly who's who of Sorority Wars</div>
    </div>
    <div class="gap-[5px] grid grid-cols-1 lg:grid-cols-3">
      <InCrowdDisplay title="Successful Events" subTitle="Events" :data="events" />
      <InCrowdDisplay title="Most Energy" subTitle="Energy" :data="energy" />
      <InCrowdDisplay title="Most Gratitudes" subTitle="Gratitudes" :data="bounties" />
      <InCrowdDisplay title="Most Fights Won" subTitle="Wins" :data="wins" />
      <InCrowdDisplay title="Most Fights Lost" subTitle="Lost" :data="losses" />
      <InCrowdDisplay title="Most Destroys" subTitle="Destroyed" :data="kills" />
      <InCrowdDisplay title="Most Depressions" subTitle="Depressions" :data="deaths" />
    </div>
  </div>
</template>

<script>
import InCrowdDisplay from '@/components/sorority-wars/incrowd/InCrowdDisplay.vue'

export default {
  name: 'SororityWarsFightWeeklyMM',
  components: { InCrowdDisplay },
  data () {
    return {
      bounties: [],
      events: [],
      energy: [],
      kills: [],
      levels: [],
      wins: [],
      losses: [],
      deaths: [],
      week: ''
    }
  },
  mounted () {
    this.$axpi().$http().get('dynamics/sorority-wars/weekly-made-bitches')
      .then(r => {
        this.bounties = r.data.lists.bounties
        this.events = r.data.lists.missions
        this.energy = r.data.lists.energy_used
        this.kills = r.data.lists.kills
        this.losses = r.data.lists.fightslost
        this.wins = r.data.lists.fightswon
        this.deaths = r.data.lists.deaths
        this.week = r.data.week
      })
  }
}
</script>

<style scoped>

</style>
