<template>
  <div>
    <div class="notify-left">
      <img src="https://assets.sororitywars.app/icons/notify/V2/fight.png" width="40" height="40" class="desktop-notify-image" />
    </div>
    <div class="notify-right">
      <div class="notify-close" @click="$emit('closeMe')">X</div>
      <div class="notify-alert">ALERT: </div>
      <div class="notify-title">
        You were terminally depressed! You lost {{$number.withCommas(notif.entry.content.expLost)}} influence!
      </div>
    </div>
    <img src="https://assets.sororitywars.app/icons/notify/V2/fight.png" width="40" height="40" class="notify-image" @click="$emit('closeMe')" />
  </div>
</template>

<script>
export default {
  name: 'Died',
  props: {
    notif: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
