import jobsAPI from '@/api/sorority-wars/jobsAPI'

const state = {
  list: []
}
const mutations = {
  setList (state, jobs) {
    state.list = jobs
  }
}
const actions = {
  all (context) {
    if (context.state.list.length !== 0) {
      return {
        success: true,
        jobs: context.state.jobs
      }
    }
    return jobsAPI.all()
      .then(r => {
        context.commit('setList', r.data.jobs)
        return r.data
      })
  },
  start (context, job) {
    return jobsAPI.start(job)
      .then(r => {
        return r.data
      })
  },
  quit (context, job) {
    return jobsAPI.quit(job)
      .then(r => {
        return r.data
      })
  }
}
const getters = {
  list: (state, getters, rootState) => {
    let activeJob = rootState.SororityWars.player.jobId
    // let playerLevel = rootState.SororityWars.player.level
    return state.list.slice(0).filter(job => {
      job.canStart = activeJob === 0
      job.canQuit = activeJob === job.id
      // return job.level >= playerLevel
      return job.level
    })
  },
  activeJob: (state, getters, rootState) => {
    let jobId = rootState.SororityWars.player.jobId
    if (!jobId) return { income: 0 }
    return state.list.find(job => job.id === jobId) || { income: 0 }
  },
  byId: state => id => {
    return state.list.find(job => job.id === id)
  }
}

export default { state, mutations, actions, getters, namespaced: true }
