import Vue from 'vue'
import Vuex from 'vuex'
import SororityWars from '@/stores/sorority-wars'
import user from '@/stores/user'
import captcha from '@/stores/captcha'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    SororityWars,
    user,
    captcha
  }
})
