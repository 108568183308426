import captchaAPI from '@/api/captchaAPI'

const state = {
  geetest_challenge: '',
  geetest_seccode: '',
  geetest_validate: ''
}

const mutations = {
  setGeetest (state, { geetest_challenge, geetest_seccode, geetest_validate }) {
    state.geetest_challenge = geetest_challenge
    state.geetest_seccode = geetest_seccode
    state.geetest_validate = geetest_validate
  }
}

const actions = {
  geetest (context, email) {
    return new Promise((resolve, reject) => {
      if (process.env.VUE_APP_GEETEST_ENABLED !== 'true') return resolve()
      captchaAPI.geetestPreauth(email)
        .then(r => {
          window.initGeetest({ lang: 'en', product: 'bind', ...r.data.geetest }, captchaObject => {
            this.captchaObj = captchaObject
            captchaObject.onSuccess(() => {
              // noinspection JSUnresolvedFunction
              context.commit('setGeetest', captchaObject.getValidate())
              resolve()
            })
            captchaObject.onReady(() => {
              captchaObject.verify()
            })
            captchaObject.onClose(() => {
              reject(new Error('No captcha'))
            })
          })
        })
    })
  }
}

const getters = {
  getGeeShit: state => {
    return {
      geetest_challenge: state.geetest_challenge,
      geetest_seccode: state.geetest_seccode,
      geetest_validate: state.geetest_validate
    }
  }
}

export default { state, mutations, getters, actions, namespaced: true }
