import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/guard-avatar'

const guardAPI = {
  showSkins () {
    return Axpi.$http().get(`${urlPrefix}/skins`)
  },
  changeBody (bodyId) {
    return Axpi.$http().post(`${urlPrefix}/change-body/${bodyId}`)
  },
  clearAll () {
    return Axpi.$http().post(`${urlPrefix}/clear`)
  },
  clearSingle (part) {
    return Axpi.$http().post(`${urlPrefix}/clear/${part}`)
  },
  equip (itemId) {
    return Axpi.$http().post(`${urlPrefix}/${itemId}/equip`)
  }
}

export default guardAPI
