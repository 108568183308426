import player from './player'
import equipment from './equipment'
import avatar from './avatar'
import jobs from './jobs'
import box from './box'
import missions from './missions'
import sweethearts from './sweethearts'
import report from './report'
import guard from '@/stores/sorority-wars/guard'

const modules = {
  player,
  equipment,
  avatar,
  guard,
  jobs,
  box,
  missions,
  sweethearts,
  report
}

export default { modules }
