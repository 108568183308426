<template>
  <div>
    <div class="device-wrapper">
      <div v-if="siteKey">
        <div v-if="errors.length > 0">
          <div v-for="error in errors" :key="error">
            {{error}}
          </div>
        </div>
        <div class="captcha-box">
          <div class="form-title p-8 left bold white">Help us verify you're human:</div>
          <div class="mt-5">
            <vue-hcaptcha
              @verify="onVerify"
              @expired="onExpired"
              :sitekey="siteKey">
            </vue-hcaptcha>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'

export default {
  name: 'RecaptchaTwo',
  components: { VueHcaptcha },
  computed: {
    siteKey () {
      return process.env.VUE_APP_HCAPTCHA_KEY
    }
  },
  data () {
    return {
      errors: []
    }
  },
  methods: {
    onVerify (response) {
      this.$axpi().$http().post('/captcha/validate', { captchaResponse: response })
        .then(r => {
          if (r.data.success) {
            location.reload()
          } else {
            this.errors = this.$errorReducer(r.data.errors)
          }
        })
    },
    onExpired () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.errors {
  color: red;
}

.form-title {
  font-size: 20px;
  background-color: #e84063;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  -moz-border-radius-topleft: 7px;
  -moz-border-radius-topright: 7px;
}

.captcha-box {
  background-color: #FFFFFF;
  border: 1px solid #e84063;
  border-radius: 8px;
  -moz-border-radius: 8px;
  width: 100%;
  display: inline-block;
}

.form-text {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

@media only screen and (min-width: 769px) {
  /* Desktop */
  .device-wrapper {
    width: 1100px;
    display: inline-block;
  }
}

@media only screen and (max-width: 768px) {
  /* Mobile / Tablet */
  .device-wrapper {
    width: 90%;
    display: inline-block;
  }
}
</style>
