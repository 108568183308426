<template>
  <div class="w-full flex-col flex space-y-[15px] px-[5px]">
    <div class="w-full text-sw-pink text-[1.65rem]">Welcome to Sorority Wars!</div>
    <div class="text-[1.30rem] font-bold">Live the fabulous life. Fabulous fashion. Fabulous parties. Fabulous Friends.</div>
    <div class="space-y-[10px] text-[1.15rem]">
      <p>Sorority Wars lets you be a fashionista and live the glamorous life of a sorority sister!</p>
      <p>In this free online game, you can hit the mall for the latest fashions and create the perfect look for your avatar!</p>
      <p>Indulge in your fashion obsession with designer clothes, shoes, handbags, sunglasses and other accessories!</p>
      <p>Light up the runway as you strut your stuff down the catwalk!</p>
      <p>When you're dressed and ready to go, hit the city to socialize with your friends in a never-ending array of social events!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuestImproved'
}
</script>

<style scoped>

</style>
