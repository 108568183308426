<template>
  <div class="notify-right">
    <div class="notify-close" @click="$emit('closeMe')">X</div>
    <div class="notify-alert">ALERT: </div>
    <div class="notify-title">
      You just accepted {{notif.count}} house requests!
    </div>
  </div>
</template>

<script>
export default {
  name: 'MassAddSuccess',
  props: {
    notif: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
