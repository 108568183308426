import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/equipment'

const equipmentApi = {
  getAll () {
    return Axpi.$http().get(`${urlPrefix}/all`)
  },
  purchase (itemId, amount) {
    return Axpi.$http().post(`${urlPrefix}/${itemId}/buy`, { amount })
  },
  sell (itemId, amount) {
    return Axpi.$http().post(`${urlPrefix}/${itemId}/sell`, { amount })
  },
  purchaseThriftShop (itemId, amount) {
    return Axpi.$http().post(`${urlPrefix}/thrift/${itemId}/buy`, { amount })
  }
}

export default equipmentApi
