import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/missions'

const missionsAPI = {
  all () {
    return Axpi.$http().get(`${urlPrefix}/all`)
  },
  do (id) {
    return Axpi.$http().post(`${urlPrefix}/${id}/do`)
  }
}

export default missionsAPI
