<template>
  <div class="device-wrapper inner-content space-y-[10px]">
    <div v-if="loggedIn">
      <logged-in />
    </div>
    <div v-else>
      <guest v-show="false" />
      <GuestImproved />
    </div>
    <sorority-wars-fight-weekly-m-m class="p-5" />
  </div>
</template>

<script>
import Guest from '@/components/home/Guest'
import LoggedIn from '@/components/home/LoggedIn'
import SororityWarsFightWeeklyMM from '@/views/sorority-wars/fight/SororityWarsFightWeeklyMM.vue'
import GuestImproved from '@/components/home/GuestImproved.vue'

export default {
  name: 'home',
  components: { GuestImproved, SororityWarsFightWeeklyMM, Guest, LoggedIn },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    }
  }
}
</script>

<style scoped>
.device-wrapper {
  @apply lg:w-[1100px] w-full inline-block;
}
  /*@media only screen and (min-width: 769px) {*/
  /*  !* Desktop *!*/
  /*  .device-wrapper {*/
  /*    width: 1100px;*/
  /*    display: inline-block;*/
  /*  }*/
  /*}*/

  /*@media only screen and (max-width: 768px) {*/
  /*  !* Mobile / Tablet *!*/
  /*  .device-wrapper {*/
  /*    width: 100%;*/
  /*    display: inline-block;*/
  /*  }*/
  /*}*/

  .welcome-title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .inner-content {
    text-align: left;
    background-color: #b7d3e8;
    border-left: 1px solid #cce9ef;
    border-right: 1px solid #cce9ef;
    margin-top: -10px;
    padding: 5px 0px 20px 0px;
  }
</style>
