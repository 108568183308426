<template>
  <div>
    <div class="form-holder mt-5">
      <div class="form-title p-8 left bold white">Login</div>
      <div class="p-5" v-if="form.show">
        <form>
          <div class="mb-5">
            <div class="bold left">Email: </div>
            <input type="email" class="home-forms" v-model="form.email" />
          </div>
          <div class="mb-5">
            <div class="bold left">Password: </div>
            <input type="password" class="home-forms" v-model="form.password" />
          </div>
          <button class="pink-button p-8 font-bold mb-5" @click="login">Login</button>
          <div class="bold pink">OR</div>
          <FacebookAuth @auth="afterLogin" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FacebookAuth from '@/components/auth/FacebookAuth'

export default {
  name: 'HomeLogin.vue',
  components: { FacebookAuth },
  data () {
    return {
      form: {
        email: '',
        password: '',
        show: false
      }
    }
  },
  mounted () {
    this.showForms()
  },
  methods: {
    showForms () {
      setTimeout(() => {
        this.form.show = true
      }, 3000)
    },
    login () {
      this.errors = null
      this.$store.dispatch('login', this.form)
        .then(r => {
          this.afterLogin(r.success, r.errors)
        })
    },
    afterLogin (success, errors) {
      if (success) {
        this.$router.push(this.$route.query['_'] || '/game')
      } else {
        this.errors = errors
      }
    }
  }
}
</script>

<style scoped>
  .form-title {
    font-size: 20px;
    background-color: #e84063;
  }

  @media only screen and (min-width: 769px) {
    /* Desktop */
    .form-holder {
      background-color: #FFFFFF;
      border: 1px solid #e84063;
      border-radius: 4px;
      -moz-border-radius: 4px;
      width: 275px;
    }

    .home-forms {
      text-align: left;
      background-color: #d1ebf1;
      border: 1px solid #000;
      border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px 0px;
      height: 20px;
      width: 99%;
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile */
    .form-holder {
      background-color: #FFFFFF;
      border: 1px solid #e84063;
      border-radius: 4px;
      -moz-border-radius: 4px;
      margin: auto;
      width: 98%;
    }

    .home-forms {
      text-align: left;
      background-color: #d1ebf1;
      border: 1px solid #000;
      border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px 0px;
      height: 20px;
      width: 99%;
    }
  }
</style>
