const state = {
  type: false,
  loading: false,
  display: false,
  data: {}
}

const mutations = {
  loading (state, val) {
    state.loading = val
    state.type = false
    state.display = false
    state.data = {}
  },
  blank (state) {
    state.type = false
    state.loading = false
    state.display = false
    state.data = {}
  },
  mod (state, { type, data }) {
    state.type = type
    state.loading = false
    state.display = true
    state.data = data
  }
}

const actions = {
  load (context, loading) {
    return new Promise((resolve) => {
      return resolve(context.commit('loading', loading))
    })
  },
  clear (context) {
    return new Promise((resolve) => {
      return resolve(context.commit('blank'))
    })
  },
  action (context, { type, data }) {
    return new Promise((resolve) => {
      return resolve(context.commit('mod', { type, data }))
    })
  }
}

export default { state, mutations, actions, namespaced: true }
