import missionsAPI from '@/api/sorority-wars/missionsAPI'

const state = {
  list: []
}

const mutations = {
  setList (state, data) {
    state.list = data
  },
  setPlayerMission (state, { id, player_mission }) {
    state.list[state.list.findIndex(item => item.id === id)].player_mission = player_mission
  }
}

const actions = {
  getAll (context) {
    return missionsAPI.all()
      .then(r => {
        context.commit('setList', r.data.missions)
        return true
      })
  },
  do (context, id) {
    return missionsAPI.do(id)
      .then(r => {
        if (r.data.success) {
          context.commit('setPlayerMission', { id, player_mission: r.data.player_mission })
        }
        return r.data
      })
  },
  doScavengerEvent (context, id) {
    return missionsAPI.do(id)
      .then(r => {
        return r.data
      })
  }
}

const getters = {
  display: (state, getters, rootState) => location => {
    const playerLevel = rootState.SororityWars.player.level
    return state.list.filter(item => item.location === location)
      .filter(item => item.once ? !item.player_mission.times : true)
      .filter(mission => mission.level <= playerLevel)
      .sort(function (a, b) {
        return a.level - b.level
      })
      .map(item => {
        let missionLevel = item.levels.find(lvl => lvl.mastery_level === item.player_mission.level)
        return {
          id: item.id,
          once: item.once,
          title: item.title,
          level: item.level,
          description: item.description,
          percent: Math.min(100, Math.floor((item.player_mission.times / missionLevel.times) * 100)),
          masteryLevel: missionLevel.mastery_level,
          team: missionLevel.team,
          cost: {
            items: missionLevel.equipment.filter(eqp => !eqp.pivot.reward).map(eqp => ({
              id: eqp.pivot.sorority_wars_equipment_id,
              name: eqp.name,
              image: eqp.image,
              amount: eqp.pivot.amount,
              isSpecial: eqp.isSpecial,
              cost: eqp.cost
            })),
            energy: missionLevel.energy
          },
          gain: {
            exp: {
              min: missionLevel.min_exp,
              max: missionLevel.max_exp
            },
            cash: {
              min: missionLevel.min_cash,
              max: missionLevel.max_cash
            }
          }
        }
      })
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
