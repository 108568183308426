import Axpi from '../api/axpi'

export default {
  toggleWall () {
    return Axpi.$http().post('dynamics/sorority-wars/profile/whiteboard/manage')
  },
  authFacebook (authResponse) {
    return Axpi.$http().post('/facebook-auth', authResponse)
      .then(res => {
        if (res.data.success) {
          this.__translateLogin(res.data)
        }
        return res
      })
  },
  register (name, email, password, password_confirmation) {
    return Axpi.$http().post('/register', { name, email, password, password_confirmation, gee: true })
      .then(res => {
        if (res.data.success) {
          this.__translateLogin(res.data)
        }
        return res
      })
  },
  login (email, password) {
    return Axpi.$http().post('/login', { email, password })
      .then(res => {
        if (res.data.success) {
          this.__translateLogin(res.data)
        }
        return res
      })
  },
  logout (send = false) {
    let data = {}
    if (send) data[send] = 1
    return Axpi.$http().post('/logout', data)
  },
  requestPasswordReset (data) {
    return Axpi.$http().post('/password/reset-request', { ...data, gee: true })
  },
  resetPassword (token, password, password_confirmation, email) {
    return Axpi.$http().post(`/password/reset/${token}`, { password, password_confirmation, email })
      .then(res => {
        if (res.data.success) {
          this.__translateLogin(res.data)
        }
        return res
      })
  },
  editSpeechBubble (text) {
    return Axpi.$http().post(`dynamics/bubble-edit`, { speech_bubble: text })
  },
  healthCheck () {
    return Axpi.$http().post('/health')
  },
  __translateLogin (dataObj) {
    this.__setHttpLoggedInUser(dataObj.access_token, dataObj.expires_in, dataObj.name, dataObj.id, dataObj.verified)
  },
  __setHttpLoggedInUser (auth, expire, name, id, verified) {
    expire += Math.floor(Date.now() / 1000)
    let data = { auth, expire, name, id, verified }
    localStorage.setItem(process.env.VUE_APP_STORE_AUTH_KEY_IN_KEY, JSON.stringify(data))
    Axpi.setToken(auth)
  },
  __validateEmail () {
    let data = this.__freshPageLoad()
    if (data) {
      let { auth, expire, name, id } = data
      this.__setHttpLoggedInUser(auth, expire, name, id, true)
    }
  },
  __freshPageLoad () {
    try {
      let data = JSON.parse(localStorage.getItem(process.env.VUE_APP_STORE_AUTH_KEY_IN_KEY))
      if (Math.floor(Date.now() / 1000) >= data.expire) {
        this.__removeHttpAndStorage()
        return false
      }
      Axpi.setToken(data.auth)
      this.healthCheck().then()
      return data
    } catch (e) {
      return false
    }
  },
  __removeHttpAndStorage () {
    localStorage.removeItem(process.env.VUE_APP_STORE_AUTH_KEY_IN_KEY)
    Axpi.setToken(null)
  }
}
