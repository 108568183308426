<template>
  <div>
    <div class="notify-left">
      <img src="https://assets.sororitywars.app/icons/notify/V2/burn_list.png" width="40" height="40" class="desktop-notify-image" />
    </div>
    <div class="notify-right">
      <div class="notify-close" @click="$emit('closeMe')">X</div>
      <div class="notify-alert">ALERT: </div>
      <div class="notify-title" v-if="notif.entry.content.type === 'BURN'">
        You were added to the burn list by
        <router-link class="pink" :to="{ name: 'SororityWarsProfileHome', params: { playerId: notif.entry.content.offender.id } }">
          {{notif.entry.content.offender.name}}
        </router-link> for ${{$number.withCommas(notif.entry.content.amount)}}!
      </div>
    </div>
    <router-link class="notify-image pink" :to="{ name: 'SororityWarsProfileHome', params: { playerId: notif.entry.content.offender.id } }">
      <img src="https://assets.sororitywars.app/icons/notify/V2/burn_list.png" width="40" height="40" class="notify-image" @click="$emit('closeMe')" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BurnList',
  props: {
    notif: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
