<template>
  <div id="app" class="w-full flex flex-col items-center" @click="fuckBots">
    <div class="flex justify-center w-full lg:w-[1100px]">
      <div class="top-nav">
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/" exact>Home</router-link>
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/add-train" v-if="loggedIn">Add Train</router-link>
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/game">Play</router-link>
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/login" v-if="!loggedIn">Login</router-link>
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/register" v-if="!loggedIn">Register</router-link>
        <router-link tag="div" class="top-nav-text" active-class="top-nav-text" to="/contact">Contact</router-link>
        <span class="top-nav-text" @click="logout" v-if="loggedIn">Logout</span>
        <div class="p-5 mt-[5px] w-full" v-if="loggedIn">
          <div class="w-full relative rounded-[8px] overflow-hidden bg-sw-blue/25 border border-sw-blue">
            <input type="text" v-model="loadID" placeholder="Enter Sister's ID here" class="w-full bg-transparent focus:ring-0 outline-none p-[10px]" />
            <div class="absolute right-0 inset-y-0 w-[100px] flex items-center justify-center bg-white/50">
              <button class="w-full h-full font-bold text-sw-blue text-[1.15rem]" @click="loadPlayer">Load</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: none;">
      <span @click="logout">Do Event</span>
      <span @click="logout">Do Again</span>
    </div>
    <recaptcha-two v-if="loginCaptchad" />
    <router-view v-else/>
    <div class="notify-placement">
      <NotificationBase v-for="notif in notifs" :key="notif.id" :event="notif" />
    </div>
  </div>
</template>
<script>
import NotificationBase from '@/components/NotificationBase'
import RecaptchaTwo from '@/components/RecaptchaTwo'
export default {
  components: { RecaptchaTwo, NotificationBase },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    },
    id () {
      return this.$store.state.user.id
    },
    name () {
      return this.$store.state.user.name
    },
    admin () {
      return this.$store.state.user.admin
    },
    loginCaptchad () {
      return this.$store.state.user.loginCaptchad
    }
  },
  watch: {
    loggedIn (val) {
      if (val) {
        this.initNotifChannel()
      } else {
      }
    }
  },
  mounted () {
    this.initNotifChannel()
  },
  data () {
    return {
      notifs: [],
      antiBot: {
        count: 0,
        pixels: ''
      },
      loadID: '',
      routerKey: 1
    }
  },
  methods: {
    initNotifChannel () {
      if (this.loggedIn) {
        this.$store.state.user.echo.private(`App.User.${this.id}`)
          .notification(notification => {
            if (notification.type === 'App\\Notifications\\HealthChange') {
              this.$store.commit('changeHealth', -notification.amount)
            } else {
              if (notification.type === 'App\\Notifications\\SororityWarsSlap') {
                this.$store.commit('changeHealth', -notification.entry.content.damage)
              }
              this.notifs.push(notification)
            }
          })
      }
    },
    loadPlayer () {
      if (this.loadID === '' || this.loadID.length < 5 || this.$route.path === '/game/profile/' + this.loadID) { return false }
      this.$router.push('/game/profile/' + this.loadID.toUpperCase())
      this.loadID = ''
    },
    logout () {
      this.$store.dispatch('logout')
        .then(r => {
          if (r.success) {
            this.$store.dispatch('modCreate')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fuckBots (e) {
      let pixels = `${e.clientX}|${e.clientY}`
      if (this.antiBot.pixels !== pixels) {
        this.antiBot.pixels = pixels
        this.antiBot.count = 0
      }
      this.antiBot.count += 1
      if (this.antiBot.count >= 1000 && this.antiBot.count === 1000) {
        e.preventDefault()
        e.stopPropagation()
        this.$store.dispatch('logout').then(r => {
          if (r.success) this.$router.push({ name: 'home' })
        })
      }
    }
  }
}
</script>
<style scoped>
  .top-nav {
    @apply bg-[#b7d3e8] rounded-[6px] flex items-center; /* Universal */
    @apply lg:py-[10px] lg:w-[1100px] lg:mb-[10px]; /* Desktop */
    @apply py-[10px] px-[5px] mb-[20px] justify-start lg:justify-center w-full flex-wrap; /* Mobile */
  }

  @media only screen and (min-width: 769px) {
    /* Desktop */

    .load-player-box {
      font-weight: bold;
      background: #d1ebf1 url("//assets.sororitywars.app/backgrounds/blue-background.jpg") no-repeat center top;
      border: 1px solid #238cbd;
      border-radius: 5px;
      -moz-border-radius: 5px;
      width: 950px;
      margin-right: 5px;
      padding: 7px 5px;
      display: inline-block;
    }

    .load-player-btn {
      width: 50px;
      display: inline-block;
    }

    .top-nav-text {
      font-size: 25px;
      text-align: center;
      color: #fb3d61 !important;
      background: #ffccd7 url(//assets.sororitywars.app/backgrounds/pink-background-v3.jpg) repeat center top;
      border: 1px solid #e84063;
      margin: 0 2px;
      padding: 2px 0;
      display: inline-block;
      cursor: pointer;
      width: 210px;
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile / Tablet */

    .load-player-box {
      font-weight: bold;
      background: #d1ebf1 url("//assets.sororitywars.app/backgrounds/blue-background.jpg") no-repeat center top;
      border: 1px solid #238cbd;
      border-radius: 5px;
      -moz-border-radius: 5px;
      width: 70%;
      margin-right: 2px;
      padding: 7px 5px;
      display: inline-block;
    }

    .load-player-btn {
      min-width: 20% !important;
      display: inline-block;
    }

    .top-nav-text {
      font-size: 20px;
      color: #fb3d61 !important;
      text-align: center;
      background: #ffccd7 url(//assets.sororitywars.app/backgrounds/pink-background-v3.jpg) repeat center top;
      border: 1px solid #e84063;
      margin: 1%;
      padding: 5px 0;
      display: inline-block;
      cursor: pointer;
      width: 47%;
    }
  }
  #app {
    text-align: center;
  }
</style>

<style>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
</style>
