<template>
  <div>
    <div class="mobile-wrapper">
      <div class="home-left left">
        <div class="text-2xl pl-[5px] text-sw-pink">Play Sorority Wars!</div>
        <div class="home-desc">Live the fabulous life. Fabulous fashion. Fabulous parties. Fabulous Friends.</div>
        <div class="home-text">
          <div class="mb-5">
            Sorority Wars lets you be a fashionista and live the glamorous life of a sorority sister!
          </div>
          <div class="mb-5">
            In this free online game, you can hit the mall for the latest fashions and create the perfect look for your avatar.
          </div>
          <div class="mb-5">
            Indulge in your fashion obsession with designer clothes, shoes, handbags, sunglasses and other accessories!
          </div>
          <div class="mb-5">
            Then light up the runway as you strut your stuff down the catwalk.
          </div>
          <div class="mb-5">
            When you're dressed and ready to go, hit the city to socialize with your friends in a never-ending array of social events!
          </div>
        </div>
      </div>
      <div class="home-right center">
        <router-link exact tag="span" class="cursor" to="/login">
          <img src="https://assets.sororitywars.app/icons/Login-V3.jpg" title="Login" class="img-options" />
        </router-link>
        <router-link exact tag="span" class="cursor" to="/register">
          <img src="https://assets.sororitywars.app/icons/Register-V3.jpg" title="Register" class="img-options" />
        </router-link>
      </div>
      <div class="home-right center" style="display: none;">
        <div v-if="homeTab === 1">
          <HomeLogin />
          <div class="mt-5 choice-holder">
            <b>Don't Have An Account?</b>
            <button class="pink-button p-8" @click="homeTab = 2">Register Now!</button>
          </div>
        </div>
        <div v-if="homeTab === 2">
          <HomeRegister />
          <div class="mt-5 choice-holder">
            <b>Already Have An Account?</b>
            <button class="pink-button p-8 font-bold mt-5" @click="homeTab = 1">Login Now!</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLogin from '@/components/home/HomeLogin'
import HomeRegister from '@/components/home/HomeRegister'
export default {
  name: 'Guest',
  components: { HomeLogin, HomeRegister },
  data () {
    return {
      homeTab: 1,
      form: {
        email: '',
        password: ''
      },
      errors: null
    }
  }
}
</script>

<style scoped>
  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .home-title {
    color: #FFFFFF;
    font-size: 25px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 5px;
  }

  .home-desc {
    font-size: 21px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 5px;
  }

  .home-text {
    font-size: 19px;
    margin: 0px 0px 10px 0px;
    padding: 5px;
    line-height: 23px;
  }

  @media only screen and (min-width: 769px) {
    /* Desktop */

    .home-left {
      margin-right: 15px;
      width: 785px;
      display: inline-block;
      vertical-align: top;
    }

    .home-right {
      width: 300px;
      display: inline-block;
      vertical-align: top;
    }

    .img-options {
      border-radius: 4px;
      -moz-border-radius: 4px;
      border: 1px solid #FFFFFF;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .choice-holder {
      width: 275px;
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile */

    .mobile-wrapper {
      position: relative;
      height: 750px;
    }

    .home-left {
      margin-right: 15px;
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 120px;
      vertical-align: top;
    }

    .home-right {
      width: 100%;
      display: inline-block;
      position: absolute;
      vertical-align: top;
    }

    .img-options {
      border-radius: 4px;
      -moz-border-radius: 4px;
      margin: 5px;
      border: 1px solid #FFFFFF;
      cursor: pointer;
      width: 45%
    }

    .choice-holder {
      margin: 5px auto;
      width: 99%;
    }
  }
</style>
