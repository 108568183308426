import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/report'

const reportAPI = {
  report (data) {
    return Axpi.$http().post(`${urlPrefix}/player`, data)
  },
  reasons () {
    return Axpi.$http().post(`${urlPrefix}/reasons`)
  }
}

export default reportAPI
