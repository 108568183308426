import sweetheartsAPI from '@/api/sorority-wars/sweetheartsAPI'

const state = {
  boyfriends: [],
  activities: [],
  sweetheart: false
}

const mutations = {
  setList (state, data) {
    state.boyfriends = data.boyfriends
    state.activities = data.activities
    state.sweetheart = data.sweetheart
    state.timer = data.timer
  }
}

const actions = {
  getAll (context) {
    return sweetheartsAPI.all()
      .then(r => {
        context.commit('setList', r.data)
        return r.data
      })
  },
  equip (context, id) {
    return sweetheartsAPI.equip(id)
      .then(r => {
        return r.data
      })
  },
  activity (context, id) {
    return sweetheartsAPI.activity(id)
      .then(r => {
        return r.data
      })
  }
}

const getters = {
  display: (state) => {
    return {
      activities: state.activities.map(activity => {
        return {
          id: activity.id,
          title: activity.title,
          image: activity.image_url,
          cost: {
            energy: activity.energy_cost,
            cash: activity.cash_cost,
            stamina: activity.stamina_cost
          },
          rewards: {
            energy: activity.energy_reward,
            cash: activity.cash_reward,
            stamina: activity.stamina_reward
          }
        }
      }),
      boyfriends: state.boyfriends.reverse().map(bf => {
        return {
          id: bf.id,
          name: bf.name,
          image: bf.image,
          attack: bf.attack,
          defense: bf.defense
        }
      }),
      sweetheart: state.sweetheart,
      active: state.boyfriends.find(boy => boy.id === state.sweetheart.sorority_wars_sweetheart_id),
      hours: state.timer
    }
  },
  getInfo: (state) => (id) => {
    return state.activities.find(info => info.id === id)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
