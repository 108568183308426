import equipmentApi from '@/api/sorority-wars/equipmentAPI'

const state = {
  inventory: [],
  sweetSayings: [
    'Gorgeous', 'Slay', 'Fabulous', 'Dang', 'Beautiful', 'Woah', 'Charming', 'Spectacular', 'Lovely', 'Slay Queen', 'Girl Boss', 'Foxy'
  ]
}
const mutations = {
  setInventory (state, inventory) {
    state.inventory = inventory
  },
  updateIndividualItemInventory (state, { id, amount, remaining }) {
    state.inventory = state.inventory.map(item => {
      if (item.id === id) {
        item.inventory.amount = amount
        if (item.is_thrift_shop_item) item.thrift_shop_remaining = remaining
        if (!item.is_thrift_shop_item) item.remaining = remaining
      }
      return item
    })
  }
}
const actions = {
  getInventory (context) {
    return equipmentApi.getAll()
      .then(res => {
        context.commit('setInventory', res.data.equipment.map(item => {
          if (!item.inventory) {
            item.inventory = {
              amount: 0
            }
          }
          return item
        }))
      })
  },
  purchase (context, { id, amount }) {
    return equipmentApi.purchase(id, amount)
      .then(res => {
        if (res.data.success) {
          context.commit('updateIndividualItemInventory', {
            id,
            amount: res.data.inventory.amount,
            remaining: res.data.remaining
          })
        }
        return res.data
      })
  },
  sell (context, { id, amount }) {
    return equipmentApi.sell(id, amount)
      .then(res => {
        if (res.data.success) {
          context.commit('updateIndividualItemInventory', { id, amount: res.data.inventory.amount, remaining: null })
        }
        return res.data
      })
  },
  purchaseThrift (context, { id, amount }) {
    return equipmentApi.purchaseThriftShop(id, amount)
      .then(res => {
        if (res.data.success) {
          context.commit('updateIndividualItemInventory', {
            id,
            amount: res.data.sorority.inventory.amount,
            remaining: res.data.sorority.remaining
          })
        }
        return res.data
      })
  }
}
const getters = {
  equipment: (state, getters, rootState) => category => {
    return state.inventory.filter(item => {
      return item.level <= rootState.SororityWars.player.level &&
        item.category === category &&
        ((
          item.isLimited && (item.inventory.amount > 0 || item.remaining > 0 || item.thrift_shop_remaining > 0)
        ) || !item.isLimited) &&
        (
          (item.isSpecial && item.inventory.amount > 0) || !item.isSpecial
        )
    }).sort(function (a, b) {
      return a.isLimited - b.isLimited || a.cost - b.cost
    })
  },
  guardEquipment: (state, getters) => {
    return state.inventory.filter(item => {
      return ((
        item.isLimited && (item.inventory.amount > 0 || item.remaining > 0)
      ) || !item.isLimited) &&
        (
          (item.isSpecial && item.inventory.amount > 0) || !item.isSpecial
        ) && (item.guard_avatar_item !== null)
    }).sort(function (a, b) {
      return a.isLimited - b.isLimited || a.cost - b.cost
    })
  },
  limitedEdition: state => {
    return state.inventory.filter(item => {
      return item.isLimited && item.remaining > 0
    }).sort(function (a, b) {
      return b.id - a.id
    })
  },
  thriftShop: state => {
    return state.inventory.filter(item => {
      return item.is_thrift_shop_item && item.thrift_shop_remaining > 0
    }).sort(function (a, b) {
      return b.id - a.id
    })
  },
  avatar: (state, getters, rootState, rootGetters) => category => {
    if (category === 0 || category === 'body') {
      return rootGetters['avatar/skins']
    } else {
      const actives = rootGetters['avatar/actives']
      return state.inventory.reduce((arr, item) => {
        if (item.avatarBodyPart === category && item.inventory.amount > 0) {
          let obj = {
            name: item.name, id: item.id, image: item.image, active: actives[item.avatarBodyPart] === item.id
          }
          arr.push(obj)
        }
        return arr
      }, []).sort(function (a, b) {
        return b.id - a.id
      })
    }
  },
  guard: (state, getters, rootState, rootGetters) => category => {
    if (category === 1 || category === 'body') {
      return rootGetters['guard/skins']
    } else {
      const actives = rootGetters['guard/actives']
      return state.inventory.reduce((arr, item) => {
        if (item.guardAvatarBodyPart === category && item.inventory.amount > 0) {
          let obj = {
            name: item.name, id: item.id, image: item.image, active: actives[item.guardAvatarBodyPart] === item.id, guardAvatarItem: item.guard_avatar_item
          }
          arr.push(obj)
        }
        return arr
      }, []).sort(function (a, b) {
        return b.id - a.id
      })
    }
  },
  guardActives: (state, getters, rootState, rootGetters) => {
    const actives = rootGetters['guard/actives']

    return state.inventory.reduce((arr, item) => {
      if (item.guardAvatarBodyPart !== null && item.inventory.amount > 0) {
        let obj = {
          name: item.name, id: item.id, image: item.image, active: actives[item.guardAvatarBodyPart] === item.id, guardAvatarItem: item.guard_avatar_item
        }
        arr.push(obj)
      }
      return arr.filter(glam => glam.active === true)
    }, []).sort(function (a, b) {
      return b.id - a.id
    })
  },
  tryOnGestureLength: state => {
    return state.sweetSayings.length
  },
  tryOnGesture: (state) => randomNumber => {
    return state.sweetSayings[randomNumber]
  }
}

export default { state, mutations, actions, getters, namespaced: true }
