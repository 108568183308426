import reportAPI from '@/api/sorority-wars/reportAPI'

const state = {
  reasons: []
}

const mutations = {
  setReasons (state, reasons) {
    state.reasons = reasons
  }
}

const actions = {
  getReasons (context) {
    if (!context.state.reasons.length) {
      return reportAPI.reasons().then(r => {
        context.commit('setReasons', r.data.reasons)
        return r.data
      })
    } else {
      return { success: true }
    }
  },
  player (context, { target, reason, freeform }) {
    return reportAPI.report({ targetId: target, reason, freeform }).then(r => r.data)
  }
}

const getters = {
  reasons: state => {
    return state.reasons.sort((a, b) => b.priority - a.priority)
  }
}

export default { state, mutations, actions, getters, namespaced: true }
