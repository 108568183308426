<template>
  <div>
    <div @click="loginToFacebook" class="facebook-btn">{{btnTitle}}</div>
  </div>
</template>

<script>
export default {
  props: {
    btnTitle: {
      type: String,
      default: 'Login with Facebook'
    }
  },
  name: 'FacebookAuth',
  mounted () {
    if (this.$route.query.fbcanvas) {
      this.loginToFacebook()
    }
  },
  methods: {
    loginToFacebook () {
      window.FB.login((response) => {
        if (response.authResponse) {
          this.$store.dispatch('authFacebook', response.authResponse).then(r => {
            if (this.$route.query.fbcanvas && r.success) {
              this.$router.push('/game')
            } else {
              this.$emit('auth', r.success, [r.error])
            }
          })
        }
      }, { scope: 'email' })
    }
  }
}
</script>

<style scoped>
  .facebook-btn {
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(66, 103, 178);
    margin-top: 5px;
    min-width: 100px;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 142, 168);
    border-image: initial;
    padding: 10px 0px;
  }

  .fb-btn {
    background-color: #4267b2;
  }
</style>
