import axios from 'axios'

function Axpi () {
  if (typeof Axpi.instance === 'object') {
    return Axpi.instance
  }

  this.axios = axios.create({
    baseURL: process.env.VUE_APP_API_SERVER,
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  })

  this.install = function (Vue) {
    let _Axpi = Axpi.instance
    Vue.prototype.$axpi = function () {
      return _Axpi
    }
  }

  this.setToken = function (token = false) {
    if (token) this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    else this.axios.defaults.headers.common['Authorization'] = null
  }

  this.$http = function () {
    return this.axios
  }

  Axpi.instance = this
  // return this
}

export default new Axpi()
