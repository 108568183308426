import userAPI from '@/api/userAPI'
import Echo from 'laravel-echo'

const getDefaultState = () => ({
  id: 0,
  name: '',
  image: '',
  verified: false,
  loggedIn: false,
  echo: false,
  favors: false,
  hired: false,
  agents: false,
  team: false,
  admin: false,
  banned: false,
  muted: false,
  donator: false,
  avatar: false,
  avatarTimer: Date.now(),
  guardAvatar: '',
  friends: [],
  speechBubble: '',
  bannedTimer: 0,
  bannedMessage: '',
  captchad: true,
  captchaResponse: '',
  betaTimer: 0,
  hasPaymentMethod: false,
  canCollectDaily: null,
  dailyReward: null,
  wallEnabled: true,
  loginCaptchad: false,
  iNotifications: true,
  nameColor: null
})

const state = getDefaultState()

const mutations = {
  login (state, { id, name, auth, verified }) {
    if (state.echo) state.echo.disconnect()
    state.id = id
    state.name = name
    state.verified = verified
    state.echo = new Echo({
      broadcaster: 'socket.io',
      host: process.env.VUE_APP_ECHO_SERVER,
      auth: { headers: { Authorization: 'Bearer ' + auth } }
    })
    state.loggedIn = true
  },
  pageLoad (state) {
    let initial = userAPI.__freshPageLoad()
    if (initial) {
      state.id = initial.id
      state.name = initial.name
      state.verified = initial.verified
      state.echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_ECHO_SERVER,
        auth: { headers: { Authorization: 'Bearer ' + initial.auth } }
      })
      state.loggedIn = true
    }
  },
  logout (state) {
    if (state.echo) state.echo.disconnect()
    Object.assign(state, getDefaultState())
  },
  validateEmail (state) {
    state.verified = true
  },
  userUpdate (
    state,
    {
      favors,
      hired,
      team,
      agents,
      admin,
      banned,
      muted,
      donor,
      image,
      avatar,
      friends,
      speech_bubble,
      banned_timer = 0,
      banned_message = '',
      captchad = false,
      guard_avatar,
      beta_timer,
      hasPaymentMethod,
      canCollectDaily,
      dailyReward,
      wall_enabled,
      loginCaptchad,
      notificationsEnabled,
      name_color
    }
  ) {
    state.favors = favors
    state.hired = hired
    state.team = team
    state.agents = agents
    state.admin = admin
    state.banned = banned
    state.muted = muted
    state.donor = donor
    state.image = image
    state.avatar = avatar
    state.guardAvatar = guard_avatar
    state.friends = friends || state.friends
    state.speechBubble = speech_bubble
    state.bannedTimer = banned_timer
    state.bannedMessage = banned_message
    state.captchad = captchad
    state.betaTimer = beta_timer
    state.hasPaymentMethod = hasPaymentMethod
    state.canCollectDaily = canCollectDaily
    state.dailyReward = dailyReward
    state.wallEnabled = wall_enabled
    state.loginCaptchad = loginCaptchad
    state.iNotifications = notificationsEnabled
    state.nameColor = name_color
  },
  imageUpdate (state, imagePath) {
    state.image = imagePath
  },
  updateAvatarTimer (state) {
    state.avatarTimer = Date.now()
  },
  speechBubbleUpdate (state, text) {
    state.speechBubble = text
  },
  setCaptchaResponse (state, res) {
    state.captchaResponse = res
  },
  setWallEnabled (state) {
    state.wallEnabled = !state.wallEnabled
  }
}

const actions = {
  authFacebook (context, authResponse) {
    return userAPI.authFacebook(authResponse).then((r) => {
      if (r.data.success) {
        context.commit('login', {
          id: r.data.id,
          name: r.data.name,
          auth: r.data.access_token,
          expire: r.data.expires_in,
          verified: r.data.verified
        })
        return { success: true }
      }
    })
  },
  register (context, { name, email, password, password_confirmation }) {
    return userAPI
      .register(name, email, password, password_confirmation)
      .then((r) => {
        if (r.data.success) {
          /** @namespace r.data.expires_in */
          /** @namespace r.data.access_token */
          context.commit('login', {
            id: r.data.id,
            name: r.data.name,
            auth: r.data.access_token,
            expire: r.data.expires_in,
            verified: r.data.verified
          })
          return { success: true }
        } else return { success: false, errors: r.data.errors }
      })
      .catch(() => {
        return {
          success: false,
          errors: {
            system: ['Unable to fetch API']
          }
        }
      })
  },
  login (context, { email, password }) {
    return userAPI
      .login(email, password)
      .then((r) => {
        if (r.data.success) {
          context.commit('login', {
            id: r.data.id,
            name: r.data.name,
            auth: r.data.access_token,
            expire: r.data.expires_in,
            verified: r.data.verified
          })
          return { success: true }
        } else return { success: false, errors: r.data.errors }
      })
      .catch(() => {
        return {
          success: false,
          errors: {
            system: ['Trouble logging in. Please try again.']
          }
        }
      })
  },
  logout (context, send = false) {
    return userAPI
      .logout(send ? context.state.id : null)
      .then(() => {
        userAPI.__removeHttpAndStorage()
        context.commit('logout')
        return { success: true }
      })
      .catch(() => {
        userAPI.__removeHttpAndStorage()
        context.commit('logout')
        return { success: true }
      })
  },
  verifyEmailSuccess (context) {
    userAPI.__validateEmail()
    context.commit('validateEmail')
  },
  updateUser (context, userObj) {
    context.commit('userUpdate', userObj)
  },
  updateProfileImage (context, imagePath) {
    context.commit('imageUpdate', imagePath)
  },
  requestPasswordReset (context, data) {
    return userAPI.requestPasswordReset(data).then((r) => {
      return r.data
    })
  },
  resetPassword (context, { token, password, password_confirmation, email }) {
    return userAPI
      .resetPassword(token, password, password_confirmation, email)
      .then((r) => {
        if (r.data.success) {
          context.commit('login', {
            id: r.data.id,
            name: r.data.name,
            auth: r.data.access_token,
            expire: r.data.expires_in,
            verified: r.data.verified
          })
        }
        return r.data
      })
      .catch(() => {
        return {
          success: false,
          errors: {
            system: ['Unable to fetch API']
          }
        }
      })
  },
  notifer (context) {
    return context.state.echo
      .private(`App.User.${context.state.id}`)
      .notification((notification) => {
        if (notification.type) {
        }
      })
  },
  bubbleEdit (context, text) {
    return userAPI.editSpeechBubble(text).then((r) => {
      if (r.data.success) {
        context.commit('speechBubbleUpdate', r.data.speech_bubble)
      }
      return r.data
    })
  },
  manageWall (context) {
    context.commit('setWallEnabled')
    userAPI.toggleWall()
  }
}

const getters = {
  avatarImage: (state, globalState) => {
    return `${state.avatar}?t=${state.avatarTimer}&n=${encodeURIComponent(globalState.playerName)}`
  },
  guardImage: (state, globalState) => {
    return `${state.guardAvatar}?t=${state.avatarTimer}&n=${encodeURIComponent(globalState.playerName)}`
  },
  userFriendsStatus: (state) => (targetHashid) => {
    return (
      state.friends.find((friend) => friend.hid === targetHashid) || {
        hid: targetHashid,
        status: 'NOSTATUS'
      }
    )
  }
}

export default { state, actions, mutations, getters }
