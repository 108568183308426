<script>
export default {
  name: 'InCrowdDisplay',
  props: ['title', 'subTitle', 'data']
}
</script>

<template>
  <div class="crowd-wrapper v-top">
    <div class="crowd-box">
      <div class="crowd-title center dark-blue bold">{{ title }}</div>
      <div class="crowd-top">
        <div class="crowd-left white">Nickname</div>
        <div class="crowd-right white">{{ subTitle }}</div>
      </div>
      <div v-if="data.length > 0" class="bg-white p-5 space-y-[5px]">
        <div class="crowd-bottom" v-for="crowd in data" :key="`${subTitle}-${crowd.hashid}-${crowd.score}`">
          <div class="crowd-left">
            <router-link class="blue-name bold cursor" :to="{ name: 'SororityWarsProfileHome', params: { playerId: crowd.hashid } }">
              <string-viewer-x :message="crowd.name" />
            </router-link>
          </div>
          <div class="crowd-right">{{$number.withCommas(crowd.score)}}</div>
        </div>
      </div>
      <div class="flex justify-center p-5 w-full h-full bg-white" v-else>
        <h3 class="pink bold">No Weekly Data</h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.blue-name {
  color: #145c89 !important;
}

.crowd-title {
  background: #d1ebf1 url("//assets.sororitywars.app/backgrounds/blue-background.jpg") no-repeat center top;
  @apply py-[15px] px-[5px];
}

.crowd-top {
  @apply bg-sw-blue p-[5px] flex items-center;
}

.crowd-box {
  @apply w-full border border-[#238cbd] rounded-[6px] overflow-hidden;
}

.crowd-left {
  @apply truncate w-[calc(100%-125px)] flex items-end h-full;
}

.crowd-right {
  @apply w-[125px] flex items-end h-full;
}

.crowd-bottom {
  @apply flex items-center h-[25px] w-full;
}
</style>
