<template>
  <div>
    <div class="form-holder mt-5">
      <div class="form-title p-8 left bold white">Register</div>
      <div class="p-5">
        <form>
          <div class="mb-5">
            <div class="bold left">Name: </div>
            <input type="email" class="home-forms" v-model="form.nme" />
          </div>
          <div class="mb-5">
            <div class="bold left">Email: </div>
            <input type="email" class="home-forms" v-model="form.email" />
          </div>
          <div class="mb-5">
            <div class="bold left">Password: </div>
            <input type="password" class="home-forms" v-model="form.password" />
          </div>
          <div class="mb-5">
            <div class="bold left">Confirm Password: </div>
            <input type="password" class="home-forms" v-model="form.password_confirmation" />
          </div>
          <button class="pink-button p-8 font-bold" @click="registerHandler">Register</button>
          <div class="bold pink mt-5">OR</div>
          <div style="text-decoration: underline; cursor: pointer; font-size: 20px; margin-top: 5px;">
            <router-link tag="div" :to="{name: 'PrivacyPolicy'}">Read Our Privacy Policy</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeRegister.vue',
  data () {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      errors: null,
      loading: false
    }
  },
  methods: {
    registerHandler () {
      this.loading = true
      this.$store.dispatch('captcha/geetest', this.form.email)
        .then(() => {
          this.register()
        })
        .finally(() => {
          this.loading = false
        })
    },
    register () {
      this.errors = null
      this.$store.dispatch('register', this.form)
        .then(r => {
          this.afterRegister(r.success, r.errors)
        })
    },
    afterRegister (success, errors) {
      if (success) {
        this.$router.push('/')
      } else {
        this.errors = errors
      }
    }
  }
}
</script>

<style scoped>
  .form-title {
    font-size: 20px;
    background-color: #e84063;
  }

  @media only screen and (min-width: 769px) {
    /* Desktop */
    .form-holder {
      background-color: #FFFFFF;
      border: 1px solid #e84063;
      border-radius: 4px;
      -moz-border-radius: 4px;
      width: 275px;
    }

    .home-forms {
      text-align: left;
      background-color: #d1ebf1;
      border: 1px solid #000;
      border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px 0px;
      height: 20px;
      width: 99%;
    }
  }

  @media only screen and (max-width: 768px) {
    /* Mobile */
    .form-holder {
      background-color: #FFFFFF;
      border: 1px solid #e84063;
      border-radius: 4px;
      -moz-border-radius: 4px;
      margin: auto;
      width: 98%;
    }

    .home-forms {
      text-align: left;
      background-color: #d1ebf1;
      border: 1px solid #000;
      border-radius: 4px;
      -moz-border-radius: 4px;
      padding: 5px 0px;
      height: 20px;
      width: 99%;
    }
  }
</style>
