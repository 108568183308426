import avatarAPI from '@/api/sorority-wars/avatarAPI'

const state = {
  skins: [],
  parts: []
}

const mutations = {
  setSkins (state, skins) {
    state.skins = skins
  },
  setParts (state, parts) {
    state.parts = parts
  },
  setPartsAndSkins (state, { parts, skins }) {
    state.skins = skins
    state.parts = parts
  },
  setPartActive (state, { part, item }) {
    state.parts = state.parts.map(localPart => {
      if (localPart.id === part || localPart.name === part) {
        localPart.active = item
      }
      return localPart
    })
  },
  setClearAll (state) {
    state.parts = state.parts.map(localPart => {
      localPart.active = null
      return localPart
    })
  },
  clearSingle (state, part) {
    state.parts = state.parts.map(localPart => {
      if (localPart.id === part || localPart.name === part) {
        localPart.active = null
      }
      return localPart
    })
  }
}

const actions = {
  getSkins (context) {
    avatarAPI.showSkins().then(res => {
      context.commit('setPartsAndSkins', res.data)
    })
  },
  equip (context, { item, part }) {
    if (part === 'body' || part === 0) {
      return avatarAPI.changeBody(item)
        .then(r => {
          if (r.data.success) {
            context.commit('setPartActive', { part, item })
            context.commit('updateAvatarTimer', null, { root: true })
          }
          return r.data
        })
    } else {
      return avatarAPI.equip(item)
        .then(r => {
          if (r.data.success) {
            context.commit('setPartActive', { part, item })
            context.commit('updateAvatarTimer', null, { root: true })
          }
          return r.data
        })
    }
  },
  clearAll (context) {
    return avatarAPI.clearAll()
      .then(r => {
        context.commit('setClearAll')
        context.commit('updateAvatarTimer', null, { root: true })
        return r.data
      })
  },
  clearPart (context, part) {
    return avatarAPI.clearSingle(part)
      .then(r => {
        context.commit('clearSingle', part)
        context.commit('updateAvatarTimer', null, { root: true })
        return r.data
      })
  }
}

const getters = {
  skins: (state, getters) => {
    const active = getters.actives.body
    return state.skins.map(item => {
      item.active = item.id === active
      return item
    })
  },
  actives: state => {
    return state.parts.reduce((obj, item) => {
      obj[item.id] = item.active
      obj[item.name] = item.active
      return obj
    }, { body: 0 })
  },
  nameToId: state => name => {
    return (state.parts.find(item => item.name === name) || { id: 0 }).id
  }
}

export default { state, mutations, actions, getters, namespaced: true }
