import Axpi from '../axpi'

const urlPrefix = 'dynamics/sorority-wars/sweetheart'

const sweetheartsAPI = {
  all () {
    return Axpi.$http().get(`${urlPrefix}/all`)
  },
  equip (id) {
    return Axpi.$http().post(`${urlPrefix}/equip/${id}`)
  },
  activity (id) {
    return Axpi.$http().post(`${urlPrefix}/activity/${id}`)
  }
}

export default sweetheartsAPI
