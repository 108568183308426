import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    if (to.name === 'SororityWarsProfileHome') return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path: '/login-change-instructions',
      name: 'SetPassword',
      component: () => import(/* webpackChunkName: "login" */ './views/SetPassword.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import(/* webpackChunkName: "login" */ './views/Contact.vue')
    },
    {
      name: 'AddTrain',
      path: '/add-train',
      meta: { auth: true, verified: true },
      component: () => import(/* webpackChunkName: "addtrain" */ './views/AddTrain.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import(/* webpackChunkName: "register" */ './views/Register.vue')
    },
    {
      name: 'ResetEmail',
      path: '/password/forgot',
      props: true,
      component: () => import(/* webpackChunkName: "passwordReset" */ './views/password/ResetEmail.vue')
    },
    {
      name: 'passwordReset',
      path: '/password/reset/:token',
      props: true,
      component: () => import(/* webpackChunkName: "passwordReset" */ './views/password/PasswordReset.vue')
    },
    {
      path: '/verification/email',
      name: 'VerificationEmail',
      component: () => import(/* webpackChunkName: "verificationEmail" */ './views/verification/VerificationEmail.vue')
    },
    {
      path: '/verification/success',
      name: 'VerificationSuccess',
      component: () => import(/* webpackChunkName: "verificationSuccess" */ './views/verification/VerificationSuccess.vue')
    },
    {
      path: '/verification/error',
      name: 'VerificationError',
      component: () => import(/* webpackChunkName: "verificationError" */ './views/verification/VerificationError.vue')
    },
    {
      path: '/guide/guard',
      name: 'SororityWarsGuardGuide',
      component: () => import(/* webpackChunkName: "SororityWarsGuardGuide" */ './views/guides/SororityWarsGuardGuide.vue')
    },
    {
      path: '/legal',
      component: () => import(/* webpackChunkName: "legal" */ './views/Legal.vue'),
      children: [
        {
          path: 'code-of-conduct',
          name: 'LegalCodeOfConduct',
          component: () => import(/* webpackChunkName: "legalCodeOfConduct" */ './views/legal/CodeOfConduct.vue')
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyPolicy',
          component: () => import(/* webpackChunkName: "PrivacyPolicy" */ './views/legal/PrivacyPolicy.vue')
        }
      ]
    },
    {
      path: '/game',
      meta: { auth: true, verified: true },
      component: () => import(/* webpackChunkName: "sorority-wars" */ './views/SororityWars.vue'),
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/SororityWarsHome.vue'),
          children: [
            {
              path: '',
              name: 'SororityWarsHomeBroadcast',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeBroadcast.vue')
            },
            {
              path: 'combat',
              name: 'SororityWarsHomeCombat',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeCombat.vue')
            },
            {
              path: 'social',
              name: 'SororityWarsHomeSocial',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeSocial.vue')
            },
            {
              path: 'votes',
              name: 'SororityWarsHomeVotes',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeVotes.vue')
            },
            {
              path: 'faq',
              name: 'SororityWarsHomeFAQ',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeFAQ.vue')
            },
            {
              path: 'poker',
              name: 'SororityWarsHomeDailyPoker',
              component: () => import(/* webpackChunkName: "sorority-wars" */ './views/sorority-wars/home/SororityWarsHomeDailyPoker.vue')
            }
          ]
        },
        {
          path: 'socialize/:socialType?',
          name: 'SororityWarsSocialize',
          props: true,
          component: () => import(/* webpackChunkName: "sorority-wars-socialize" */ './views/sorority-wars/SororityWarsSocialize.vue')
        },
        {
          path: 'fight',
          component: () => import(/* webpackChunkName: "sorority-wars-fight" */ './views/sorority-wars/SororityWarsFight.vue'),
          children: [
            {
              path: '',
              name: 'SororityWarsFightMainList',
              component: () => import(/* webpackChunkName: "sorority-wars-fight" */ './views/sorority-wars/fight/SororityWarsFightMainList.vue')
            },
            {
              path: 'hitlist',
              name: 'SororityWarsFightHitList',
              component: () => import(/* webpackChunkName: "sorority-wars-fight" */ './views/sorority-wars/fight/SororityWarsFightHitList.vue')
            },
            {
              path: 'incrowd',
              name: 'SororityWarsFightInCrowd',
              component: () => import(/* webpackChunkName: "sorority-wars-fight" */ './views/sorority-wars/fight/SororityWarsFightInCrowd.vue')
            },
            {
              path: 'weekly-incrowd',
              name: 'SororityWarsFightWeeklyMM',
              component: () => import(/* webpackChunkName: "sorority-wars-fight" */ './views/sorority-wars/fight/SororityWarsFightWeeklyMM.vue')
            }
          ]
        },
        {
          path: 'glam',
          component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/SororityWarsGlam.vue'),
          children: [
            {
              path: '',
              component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/SororityWarsGlamWardrobe.vue'),
              children: [
                {
                  path: '',
                  name: 'SororityGlamAccessories',
                  component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/categories/SororityGlamAccessories.vue')
                },
                {
                  path: 'clothing',
                  name: 'SororityGlamClothing',
                  component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/categories/SororityGlamClothing.vue')
                },
                {
                  path: 'rides',
                  name: 'SororityGlamRides',
                  component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/categories/SororityGlamRides.vue')
                },
                {
                  path: 'spouses',
                  name: 'SororityGlamBoyfriends',
                  component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/categories/SororityGlamBoyfriends.vue')
                }
              ]
            },
            {
              path: 'sweethearts',
              name: 'SororityWarsGlamSweethearts',
              component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/SororityWarsGlamSweethearts.vue')
            },
            {
              path: 'guard',
              name: 'SororityWarsGuardEquipment',
              component: () => import(/* webpackChunkName: "sorority-wars-glam" */ './views/sorority-wars/glam/SororityWarsGuardEquipment.vue')
            }
          ]
        },
        {
          path: 'catwalk',
          name: 'SororityWarsCatwalk',
          component: () => import(/* webpackChunkName: "sorority-wars-catwalk" */ './views/sorority-wars/SororityWarsCatwalk.vue')
        },
        {
          path: 'housemom',
          component: () => import(/* webpackChunkName: "sorority-wars-housemom" */ './views/sorority-wars/SororityWarsHouseMom.vue'),
          children: [
            {
              path: '',
              name: 'SororityWarsHouseMom',
              component: () => import(/* webpackChunkName: "sorority-wars-housemom-points" */ './views/sorority-wars/housemom/SororityWarsHouseMomPoints.vue')
            },
            {
              path: 'vip',
              name: 'SororityWarsVIP',
              component: () => import(/* webpackChunkName: "sorority-wars-housemom-points" */ './views/sorority-wars/housemom/SororityWarsVIP.vue')
            },
            {
              path: 'casino',
              name: 'SororityWarsCasinoHome',
              component: () => import(/* webpackChunkName: "sorority-wars-housemom-points" */ './views/sorority-wars/casino/SororityWarsCasinoHome.vue')
            },
            {
              path: 'blackjack',
              name: 'SororityWarsBlackjack',
              component: () => import(/* webpackChunkName: "sorority-wars-housemom-blackjack" */ './views/sorority-wars/casino/SororityWarsBlackjack.vue')
            },
            {
              path: 'luckydraw',
              name: 'SororityWarsLuckyDraw',
              component: () => import(/* webpackChunkName: "sorority-wars-housemom-luckydraw" */ './views/sorority-wars/casino/SororityWarsLuckyDraw.vue')
            }
          ]
        },
        {
          path: 'myself',
          component: () => import(/* webpackChunkName: "sorority-wars-myself" */ './views/sorority-wars/SororityWarsMyself.vue'),
          children: [
            {
              path: '',
              name: 'SororityWarsMyselfSkills',
              component: () => import(/* webpackChunkName: "sorority-wars-myself" */ './views/sorority-wars/myself/SororityWarsMyselfSkills.vue')
            },
            {
              path: 'styles/:bodyPart',
              name: 'SororityWarsMyselfStyles',
              props: true,
              component: () => import(/* webpackChunkName: "sorority-wars-myself-styles" */ './views/sorority-wars/myself/SororityWarsMyselfStyle.vue')
            },
            {
              path: 'guard/:bodyPart',
              name: 'SororityWarsMyselfGuardAvatar',
              props: true,
              component: () => import(/* webpackChunkName: "sorority-wars-myself-styles" */ './views/sorority-wars/myself/SororityWarsMyselfGuardAvatar.vue')
            },
            {
              path: 'characters',
              name: 'SororityWarsMyselfCharacters',
              component: () => import(/* webpackChunkName: "sorority-wars-myself" */ './views/sorority-wars/myself/SororityWarsMyselfCharacters.vue')
            },
            {
              path: 'jobs',
              name: 'SororityWarsMyselfJob',
              component: () => import(/* webpackChunkName: "sorority-wars-myself" */ './views/sorority-wars/myself/SororityWarsMyselfJob.vue')
            },
            {
              path: 'settings',
              name: 'SororityWarsMyselfSettings',
              component: () => import(/* webpackChunkName: "sorority-wars-myself" */ './views/sorority-wars/myself/SororityWarsMyselfSettings.vue')
            }
          ]
        },
        {
          path: 'myhouse',
          component: () => import(/* webpackChunkName: "sorority-wars-myhouse" */ './views/sorority-wars/SororityWarsMyHouse.vue'),
          children: [
            {
              path: '',
              name: 'SororityWarsMyHouseInvites',
              component: () => import(/* webpackChunkName: "sorority-wars-myhouse" */ './views/sorority-wars/myhouse/SororityWarsMyHouseInvites.vue')
            },
            {
              path: 'members',
              name: 'SororityWarsMyHouseMembers',
              component: () => import(/* webpackChunkName: "sorority-wars-myhouse" */ './views/sorority-wars/myhouse/SororityWarsMyHouseMembers.vue')
            },
            {
              path: 'blocked',
              name: 'SororityWarsBlocked',
              component: () => import(/* webpackChunkName: "sorority-wars-myhouse" */ './views/sorority-wars/myhouse/SororityWarsBlocked.vue')
            }
          ]
        },
        {
          path: 'profile/:playerId',
          props: true,
          name: 'SororityWarsProfileHome',
          component: () => import(/* webpackChunkName: "sorority-wars-profile" */ './views/sorority-wars/SororityWarsProfile.vue')
        },
        {
          path: 'help',
          name: 'SororityWarsHelp',
          component: () => import(/* webpackChunkName: "sorority-wars-help" */ './views/sorority-wars/SororityWarsHelp.vue')
        },
        {
          path: '*',
          name: 'SororityWarsNotFound',
          component: () => import(/* webpackChunkName: "sorority-wars-help" */ './views/sorority-wars/SororityWarsNotFound.vue')

        }
      ]
    },
    {
      path: '/payments/success',
      name: 'PaymentsSuccess',
      component: () => import(/* webpackChunkName: "PaymentsSuccess" */ './views/payments/PaymentsSuccess.vue')
    },
    {
      path: '/payments/failure',
      name: 'PaymentsFailure',
      component: () => import(/* webpackChunkName: "PaymentsFailure" */ './views/payments/PaymentsFailure.vue')
    },
    {
      path: '/donator',
      name: 'Donator',
      component: () => import(/* webpackChunkName: "Donator" */ './views/Donator.vue')
    },
    {
      path: '/nlp/:key',
      props: true,
      component: () => import(/* webpackChunkName: "NlPK" */ './views/Nlp.vue')
    },
    {
      path: '*',
      name: 'FourOhFourError',
      component: () => import(/* webpackChunkName: "fourOhFour" */ './views/FourOhFour.vue')
    }
  ]
})

export default router
