export default {
  install (vue) {
    vue.prototype.$number = {
      withCommas (number) {
        if (number < 1000) return number
        number += ''
        const c = number.split('.')
        let f = c[0]
        const a = c.length > 1 ? '.' + c[1] : ''
        const b = /(\d+)(\d{3})/
        while (b.test(f)) {
          f = f.replace(b, '$1,$2')
        }
        return f + a
      },
      withCommasAndSuffix (num, divisor, divisorMax, suffix) {
        divisor = Math.abs(divisor)
        num = Math.abs(num)
        if (num < divisor) {
          return this.withCommas(num)
        }
        let d = 0
        if (num / divisorMax >= 100) {
          d = Math.floor(num / divisorMax)
          return this.withCommas(d) + suffix
        } else {
          d = Math.floor(num * 100 / divisorMax) / 100
          return d + suffix
        }
      },
      withAbbreviation (a) {
        if (a >= 1000000000000) {
          return this.withCommasAndSuffix(a, 1000000000000, 1000000000000, 'T')
        } else {
          if (a >= 1000000000) {
            return this.withCommasAndSuffix(a, 1000000000, 1000000000, 'B')
          } else {
            if (a >= 1000000) {
              return this.withCommasAndSuffix(a, 1000000, 1000000, 'M')
            } else {
              if (a >= 10000) {
                return this.withCommasAndSuffix(a, 1000, 1000, 'K')
              } else {
                if (a <= -1000000000000) {
                  return this.withCommasAndSuffix(a, -1000000000000, -1000000000000, 'T')
                } else {
                  if (a <= -1000000000) {
                    return this.withCommasAndSuffix(a, -1000000000, -1000000000, 'B')
                  } else {
                    if (a <= -1000000) {
                      return this.withCommasAndSuffix(a, -1000000, -1000000, 'M')
                    } else {
                      if (a <= -10000) {
                        return this.withCommasAndSuffix(a, -1000, -1000, 'K')
                      } else {
                        return this.withCommas(a)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
