import Axpi from './axpi'
const urlPrefix = '/dynamics/sorority-wars/'

export default {
  stats () {
    return Axpi.$http().post(`${urlPrefix}stats`)
  },
  newGame (name, playerClass) {
    return Axpi.$http().post(`${urlPrefix}create`, { name, 'class': playerClass })
  },
  increaseAttribute (attribute, amountOfSkillPoints = null) {
    return Axpi.$http().post(`${urlPrefix}attribute/increase`, { attribute, amount: amountOfSkillPoints || undefined })
  },
  getNotifications () {
    return Axpi
  }
}
